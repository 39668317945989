export default {
  production: {
    charon: 'https://c.phxp.huddle.tech',
    cronos: 'https://cronos.phxp.huddle.tech',
    idm: 'https://idm.phxp.huddle.tech',
    socketApi: 'https://sio.phxp.huddle.tech',
    gql: 'https://gqls.phxp.huddle.tech/graphql',
    paramsManager: 'https://params-manager.phxp.huddle.tech',
    replay: '',
    demoUrl: 'https://demo.huddle.tech',
    accountInfo: 'https://account-info.phxp.huddle.tech',
    betTicker: 'https://btr.phxp.huddle.tech',
    betTickerGql: 'https://betgqls.phxp.huddle.tech/graphql',
    dashboardsManager: 'https://dashboards-manager.phxp.huddle.tech',
    cognitoPool: {
      region: 'us-east-2',
      UserPoolId: 'us-east-2_7ixLbwXDl',
      ClientId: '6kr8jmkku5b11p5rlrfet704s0',
      scope: 'openid',
    },
    sentryDSN: 'https://abce91d633c1e95c29367cf78bd9eff3@o971626.ingest.us.sentry.io/4507220304920576',
    healthAggregator: 'https://health-aggregator.phxp.huddle.tech',
  },
  dev: {
    charon: 'https://c.d.huddle.tech',
    cronos: 'https://cronos.d.huddle.tech',
    idm: 'https://idm.d.huddle.tech',
    socketApi: 'https://sio.d.huddle.tech',
    gql: 'https://gqls.d.huddle.tech/graphql',
    replay: 'https://replay.d.huddle.tech',
    demoUrl: 'https://demo-huddle-dev.netlify.app',
    paramsManager: 'https://params-manager.d.huddle.tech',
    accountInfo: 'https://account-info.d.huddle.tech',
    betTicker: 'https://btr.d.huddle.tech',
    betTickerGql: 'https://betgqls.d.huddle.tech/graphql',
    dashboardsManager: 'https://dashboards-manager.d.huddle.tech',
    cognitoPool: {
      region: 'eu-west-1',
      UserPoolId: 'eu-west-1_gpOlmyIX9',
      ClientId: '2qfjog0k4ce96oo4fvdf8jr2e',
      scope: 'openid',
    },
    sentryDSN: 'https://abce91d633c1e95c29367cf78bd9eff3@o971626.ingest.us.sentry.io/4507220304920576',
    healthAggregator: 'https://health-aggregator.d.huddle.tech',
  },
  staging: {
    charon: 'https://c.s.huddle.tech',
    cronos: 'https://cronos.s.huddle.tech',
    idm: 'https://idm.s.huddle.tech',
    socketApi: 'https://sio.s.huddle.tech',
    gql: 'https://gqls.s.huddle.tech/graphql',
    replay: 'https://replay.s.huddle.tech',
    demoUrl: 'https://demo-staging.huddle.tech',
    paramsManager: 'https://params-manager.s.huddle.tech',
    accountInfo: 'https://account-info.s.huddle.tech',
    betTicker: 'https://btr.s.huddle.tech',
    betTickerGql: 'https://betgqls.s.huddle.tech/graphql',
    dashboardsManager: 'https://dashboards-manager.s.huddle.tech',
    cognitoPool: {
      region: 'eu-west-1',
      UserPoolId: 'eu-west-1_gpOlmyIX9',
      ClientId: '2qfjog0k4ce96oo4fvdf8jr2e',
      scope: 'openid',
    },
    sentryDSN: 'https://abce91d633c1e95c29367cf78bd9eff3@o971626.ingest.us.sentry.io/4507220304920576',
    healthAggregator: 'https://health-aggregator.s.huddle.tech',
  },
};
