<template>
  <div
    ref="rootElement"
    :class="[
      'navbar-feed',
      { 'navbar-feed--variant-success': allProductsUp },
      { 'navbar-feed--variant-danger': !allProductsUp },
    ]"
    @click="toggleDropdown"
  >
    <div
      class="navbar-feed__button"
      v-if="operator"
    >
      <NavbarFeedIcon
        :variant="productIconVariant"
      />
      {{ buttonLabel }}
    </div>
    <div
      v-if="isDropdownToggled"
      class="navbar-feed__dropdown"
    >
      <div
        v-for="product in operatorProducts"
        :key="product.product"
        class="navbar-feed__dropdown-item"
      >
        <NavbarFeedIcon
          :variant="getProductIconVariant(product)"
        />
        {{ product.product === 'odds-feed' ? 'Odds feed' : 'SGP' }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { find } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import NavbarFeedIcon from './NavbarFeedIcon';

export default {
  components: {
    NavbarFeedIcon,
  },
  setup() {
    const store = useStore();

    const rootElement = ref(null);
    const isDropdownToggled = ref(false);
    const toggleDropdown = () => {
      isDropdownToggled.value = !isDropdownToggled.value;
    };

    const selectedOperator = computed(() => store.getters.operator);
    const operator = computed(() => find(store.getters.operatorsList, (operatorItem) => operatorItem.operatorId === selectedOperator.value));
    const operatorProducts = computed(() => operator.value?.productHealthStatuses);

    const oddsFeedProduct = computed(() => find(operatorProducts.value, (product) => product.product === 'odds-feed'));
    const oddsFeedUp = computed(() => (oddsFeedProduct.value?.overrideStatus && oddsFeedProduct.value?.overrideStatus === 'UP')
      || (!oddsFeedProduct.value?.overrideStatus && oddsFeedProduct.value?.productStatus === 'UP'));
    const sgpProduct = computed(() => find(operatorProducts.value, (product) => product.product === 'sgp'));
    const sgpUp = computed(() => (sgpProduct.value?.overrideStatus && sgpProduct.value?.overrideStatus === 'UP')
      || (!sgpProduct.value?.overrideStatus && sgpProduct.value?.productStatus === 'UP'));
    const allProductsDown = computed(() => !oddsFeedUp.value && !sgpUp.value);
    const allProductsUp = computed(() => oddsFeedUp.value && sgpUp.value);
    const buttonIcon = computed(() => {
      if (!allProductsUp.value) return 'exclamation';
      return 'check';
    });
    const buttonLabel = computed(() => {
      if (allProductsDown.value) return 'All products down';
      if (allProductsUp.value) return 'Products operational';
      const productName = !oddsFeedUp.value ? 'Odds feed' : 'SGP';
      return `${productName} down`;
    });
    const productIconVariant = computed(() => {
      if (allProductsUp.value) return 'success';
      return 'danger';
    });
    const getProductIconVariant = (product) => {
      if ((product.overrideStatus && product.overrideStatus === 'UP') || (!product.overrideStatus && product.productStatus === 'UP')) return 'success';
      if ((product.overrideStatus && product.overrideStatus === 'DOWN') || (!product.overrideStatus && product.productStatus === 'DOWN')) return 'danger';
      return '';
    };

    onClickOutside(rootElement, () => {
      isDropdownToggled.value = false;
    });

    return {
      rootElement,
      isDropdownToggled,
      toggleDropdown,
      buttonIcon,
      buttonLabel,
      productIconVariant,
      getProductIconVariant,
      operatorProducts,
      allProductsUp,
      operator,
    };
  },
};
</script>

<style lang="scss">
.navbar-feed {
  $block: &;
  position: relative;
  user-select: none;

  &__button {
    display: flex;
    height: 32px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    color: var(--neutral-text-default-resting, #191414);
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    margin-top: 4px;
    background-color: $white;
    color: $black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 1);
    z-index: $dropdownMenuZIndex;

    &-item {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      min-width: 100%;
      height: 44px;
      padding: 0 12px;
      gap: 4px;
    }
  }

  &--variant-success {
    #{$block}__button {
      background: var(--success-bg-weak-resting, #E5F8EE);
    }
  }

  &--variant-danger {
    #{$block}__button {
      background: var(--danger-bg-weak-resting, #FEE);
    }
  }
}
</style>
