<template>
  <div class="client-settings-operator">
    <div class="client-settings-operator-cell">
      {{ operator.operatorId }}
    </div>
    <div
      class="client-settings-operator-cell product-cell"
      v-if="oddsFeedProduct"
    >
      <div :class="['client-settings-cell-toggle', {'down': !oddsFeedProductStatus}]">
        <span>OF</span>
        <Toggle
          :model-value="oddsFeedProductStatus"
          :disabled="oddsFeedLoading || resetOverrideLoading"
          @update:model-value="updateOddsFeed"
        />
        <div :class="['client-settings-mode', {'auto': !oddsFeedProduct.overrideStatus, 'manual': oddsFeedProduct.overrideStatus}]">
          {{ oddsFeedProduct.overrideStatus ? 'MANUAL' : 'AUTO' }}
        </div>
        <Icon
          v-if="oddsFeedProduct.overrideStatus"
          @click="resetOverride('odds-feed')"
          title="Reset manual override"
          name="refresh-ccw-1"
        />
      </div>
    </div>
    <div
      class="client-settings-operator-cell product-cell"
      v-if="sgpProduct"
    >
      <div :class="['client-settings-cell-toggle', {'down': !sgpProductStatus}]">
        <span>SGP</span>
        <Toggle
          :model-value="sgpProductStatus"
          :disabled="sgpLoading || resetOverrideLoading"
          @update:model-value="updateSgp"
        />
        <div :class="['client-settings-mode', {'auto': !sgpProduct.overrideStatus, 'manual': sgpProduct.overrideStatus}]">
          {{ sgpProduct.overrideStatus ? 'MANUAL' : 'AUTO' }}
        </div>
        <Icon
          v-if="sgpProduct.overrideStatus"
          @click="resetOverride('sgp')"
          title="Reset manual override"
          name="refresh-ccw-1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import { filter, find } from 'lodash';
/* import Button from '@/components/common/Button'; */
import Icon from '@/components/common/Icon';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import * as api from '@/services/api';
import Toggle from './Toggle';

export default {
  emits: ['onLeaveCancel'],
  components: {
    Toggle,
    /* Button, */
    Icon,
  },
  props: {
    operator: {
      required: true,
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const clientSettingsModalRef = ref(null);
    const oddsFeedLoading = ref(false);
    const sgpLoading = ref(false);
    const resetOverrideLoading = ref(false);
    const { SUPER_ADMIN_CLIENT } = getSuperAdminData();
    const operatorsList = computed(() => filter(store.getters.operatorsList, (operator) => operator.operatorId !== SUPER_ADMIN_CLIENT));
    const leaveCancel = () => emit('onLeaveCancel');
    const sgpProduct = computed(() => find(props.operator?.productHealthStatuses, (product) => product.product === 'sgp'));
    const oddsFeedProduct = computed(() => find(props.operator?.productHealthStatuses, (product) => product.product === 'odds-feed'));

    const oddsFeedProductStatus = computed(() => {
      if (oddsFeedProduct.value?.overrideStatus) return oddsFeedProduct.value.overrideStatus === 'UP';
      return oddsFeedProduct.value?.productStatus === 'UP';
    });

    const sgpProductStatus = computed(() => {
      if (sgpProduct.value?.overrideStatus) return sgpProduct.value.overrideStatus === 'UP';
      return sgpProduct.value?.productStatus === 'UP';
    });

    const updateOddsFeed = () => {
      let newStatus = oddsFeedProduct.value?.productStatus === 'UP' ? 'DOWN' : 'UP';
      if (oddsFeedProduct.value?.overrideStatus) {
        newStatus = oddsFeedProduct.value?.overrideStatus === 'UP' ? 'DOWN' : 'UP';
      }
      oddsFeedLoading.value = true;
      const payload = {
        operatorId: props.operator?.operatorId,
        product: 'odds-feed',
        status: newStatus,
      };
      api.productStatusManualOverride(payload)
        .then(() => {
          store.dispatch('getAllOperators');
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          oddsFeedLoading.value = false;
        });
    };

    const updateSgp = () => {
      let newStatus = sgpProduct.value?.productStatus === 'UP' ? 'DOWN' : 'UP';
      if (sgpProduct.value?.overrideStatus) {
        newStatus = sgpProduct.value?.overrideStatus === 'UP' ? 'DOWN' : 'UP';
      }
      sgpLoading.value = true;
      const payload = {
        operatorId: props.operator?.operatorId,
        product: 'sgp',
        status: newStatus,
      };
      api.productStatusManualOverride(payload)
        .then(() => {
          store.dispatch('getAllOperators');
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          sgpLoading.value = false;
        });
    };

    const resetOverride = (product) => {
      if (resetOverrideLoading.value) return;
      resetOverrideLoading.value = true;
      const payload = {
        operatorId: props.operator?.operatorId,
        product,
      };
      api.resetProductStatusManualOverride(payload)
        .then(() => {
          store.dispatch('getAllOperators');
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          resetOverrideLoading.value = false;
        });
    };

    onClickOutside(clientSettingsModalRef, () => {
      leaveCancel();
    });

    return {
      leaveCancel,
      operatorsList,
      clientSettingsModalRef,
      sgpProduct,
      oddsFeedProduct,
      oddsFeedLoading,
      sgpLoading,
      updateOddsFeed,
      updateSgp,
      oddsFeedProductStatus,
      sgpProductStatus,
      resetOverride,
      resetOverrideLoading,
    };
  },
};
</script>
<style lang="scss">
.client-settings-operator {
  height: 100%;
  width: 100%;
  display: flex;

  .client-settings-operator-cell {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;

    &.product-cell {
      width: 40%;
    }

    .client-settings-cell-toggle {
      display: flex;
      gap: 4px;
      height: 100%;
      align-items: center;

      .toggle {
        background-color: #00BC57;
      }

      &.down {
        .toggle {
          background-color: #FF2E2D;
        }
      }

      .client-settings-mode {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #F0F0F0;
        color: #CDCDCD;
        font-family: 'Rubik';
        font-style: italic;
        font-weight: 600;
        font-size: 10px;
        border-radius: 2px;
        outline: 1px solid #F0F0F0;
        outline-offset: 1px;
        line-height: 12px;
        padding: 2px 5px;
        margin-left: 4px;

        &.auto {
          background: #175FFF;
          outline-color: #175FFF;
          color: #fff;
        }

        &.manual {
          background: #FFC531;
          outline-color: #FFC531;
          color: #191414;
        }
      }
      .icon {
        height: 14px;
        width: 14px;
        margin-left: 4px;
        cursor: pointer;
        svg {
          path {
            fill: #FF2E2D;
          }
        }
      }
    }
  }
}
</style>
