<template>
  <div
    class="client-settings-background-cover"
  />
  <div
    class="client-settings-popup-wrapper"
    ref="clientSettingsModalRef"
  >
    <div class="client-settings-header">
      Client settings
    </div>
    <div class="client-settings-message">
      Manually override product health statuses for operators.
    </div>
    <div class="client-settings-body">
      <div
        class="operator-row"
        v-for="(operator, index) in operatorsList"
        :key="index"
      >
        <ClientSettingsModalRow :operator="operator" />
      </div>
    </div>
    <div class="client-settings-buttons">
      <Button
        @click="leaveCancel"
      >
        Close
      </Button>
    </div>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import Button from '@/components/common/Button';
/* import Icon from '@/components/common/Icon'; */
import ClientSettingsModalRow from './ClientSettingsModalRow';

export default {
  emits: ['onLeaveCancel'],
  components: {
    ClientSettingsModalRow,
    Button,
    /* Icon, */
  },
  props: {
    eventName: {
      type: String,
      default: () => 'event',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const clientSettingsModalRef = ref(null);
    const operatorsList = computed(() => store.getters.operatorsList);
    const leaveCancel = () => emit('onLeaveCancel');

    onClickOutside(clientSettingsModalRef, () => {
      leaveCancel();
    });

    return {
      leaveCancel,
      operatorsList,
      clientSettingsModalRef,
    };
  },
};
</script>
<style lang="scss">
.client-settings-background-cover {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $modalZIndex;
  pointer-events: all;

}
.client-settings-popup-wrapper {
    position: absolute;
    width: 520px;
    height: 542px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    z-index: $modalZIndex;

    .client-settings-header {
      font-size: 16px;
      color: #191414;
      font-weight: 600;
      padding: 0 16px;
      border-bottom: 1px solid $gray400;
      height: 56px;
      display: flex;
      align-items: center;

    }

    .client-settings-message {
      padding: 0 16px;
      height: 52px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray400;

      span {
        font-weight: 600;
        color: #191414;
      }
    }

    .client-settings-body {
      width: 100%;
      height: 370px;
      overflow-y: auto;

      .operator-row {
        height: 44px;
        width: 100%;
        padding: 0 16px;
        border-bottom: 1px solid $gray400;

        &:last-child {
          border-bottom: 0;
          .client-settings-operator-cell {
            .client-settings-cell-toggle {
              .toggle {
                position: relative;
                bottom: 1px;
              }
              .client-settings-mode {
                position: relative;
                bottom: 1px;
              }
            }
          }
        }
      }
    }

    .client-settings-buttons {
      width: 100%;
      height: 64px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      padding: 0 16px;
      border-top: 1px solid $gray400;

      .button {
        padding: 8px 24px;
      }
    }
}
</style>
